// src/pages/MyPlant.js
import React from "react";
import NavBar from "../components/NavBar/NavBar";

const MyPlant = () => {
  return (
    <>
      <NavBar/>
      <main className="web-view">
        <div className="write-section">
          <div className="write-content">
            <h1 className="">Wellness Garden</h1>
            <p className="">Grow mindfulness and greenery at the same time—every journal entry nurtures your virtual Wellness Garden.</p>
            <p className="coming-soon">Coming soon!</p>
          </div>
        </div>
      </main>
    </>
  );
};

export default MyPlant;
