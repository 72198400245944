import React, { useState, useEffect } from 'react';
import Logo from '../../assets/CalmLeaf-Logo-Alt.svg';
import { supabase } from '../../supabaseClient'; // Import the Supabase client
import { EntriesIcon, GoalsIcon, NoteIcon, PlantIcon, UserIcon } from "../../assets/icons/icons"; // Import icons
import './style.css';

export default function NavBar(props) {
    // Nav Icon States
    const [isDashboard, setIsDashboard] = useState(false);
    const [isWrite, setIsWrite] = useState(false);
    const [isEntries, setIsEntries] = useState(false);
    const [isMyPlant, setIsMyPlant] = useState(false);
    const [isMyGoals, setIsMyGoals] = useState(false);

    const [isAuthenticated, setIsAuthenticated] = useState(false); // Check user login state

    const [isSideNav, setIsSideNav] = useState(true);

    useEffect(() => {
        const checkAuthSession = async () => {
            const { data: sessionData } = await supabase.auth.getSession();
            setIsAuthenticated(!!sessionData.session); // Set isAuthenticated to true if a session exists
        };

        checkAuthSession();

        const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
            setIsAuthenticated(!!session); // Update auth state when user logs in or logs out
        });

        return () => {
            authListener.subscription?.unsubscribe();
        };
    }, []);

    const handleLogout = async () => {
        await supabase.auth.signOut();
        setIsAuthenticated(false);
        window.location.href = '/'; // Redirect to home after logout
    };

    useEffect(() => {
        const currentPath = window.location.pathname;
        setIsDashboard(currentPath === '/');
        setIsWrite(currentPath === '/write');
        setIsEntries(currentPath === '/entries');
        setIsMyPlant(currentPath === '/my-plant');
        setIsMyGoals(currentPath === '/my-goals');
    }, []);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", checkMobile);
        checkMobile();
        return () => window.removeEventListener("resize", checkMobile);
    }, []);

    return (
        <>
            {isMobile ? (
                <>
                    <div className="mobile-view">
                        <footer className="mobile-nav">
                            <button
                                className={`nav-icon-btn ${isDashboard ? 'mobile-nav-selected' : ''}`}
                                onClick={(e) => window.location.href = '/'}
                            >
                                <img src={Logo} alt='logo' className="icon" />
                            </button>
                            <button
                                className={`nav-icon-btn ${isEntries ? 'mobile-nav-selected' : ''}`}
                                onClick={(e) => window.location.href = '/entries'}
                            >
                                <EntriesIcon className="icon" />
                            </button>

                            {/* Middle button for Entry Icon */}
                            <div className="entry-button-container">
                                <button
                                    className="entry-button"
                                    onClick={(e) => window.location.href = '/write'}
                                >
                                    <NoteIcon className="entry-icon" />
                                </button>
                            </div>

                            <button
                                className={`nav-icon-btn ${isMyGoals ? 'mobile-nav-selected' : ''}`}
                                onClick={(e) => window.location.href = '/my-goals'}
                            >
                                <GoalsIcon className="icon" />
                            </button>
                            <button
                                className={`nav-icon-btn ${isMyPlant ? 'mobile-nav-selected' : ''}`}
                                onClick={(e) => window.location.href = '/my-plant'}
                            >
                                <PlantIcon className="icon" />
                            </button>
                        </footer>
                    </div>
                </>
            ) : (
                <>
                    <header className="header-dashboard">
                        <nav className="web-nav">
                            <div className="logo-container" onClick={() => window.location.href = '/'}>
                                <img src={Logo} className="logo" alt="CalmLeaf Logo" />
                                <h1 className={`logo-text ${isSideNav ? '' : 'display-none'}`}>CalmLeaf</h1>
                            </div>
                            <div className="web-nav-icon-container">
                                <div>
                                    <div className={`icon-container ${isWrite ? 'nav-selected' : ''}`} onClick={() => window.location.href = '/write'}>
                                        <NoteIcon className="icon" />
                                        <span className={`icon-title ${isSideNav ? '' : 'display-none'}`}>Write</span>
                                    </div>
                                    <div className={`icon-container ${isEntries ? 'nav-selected' : ''}`} onClick={() => window.location.href = '/entries'}>
                                        <EntriesIcon className="icon" />
                                        <span className={`icon-title ${isSideNav ? '' : 'display-none'}`}>Entries</span>
                                    </div>
                                    <div className={`icon-container ${isMyPlant ? 'nav-selected' : ''}`} onClick={() => window.location.href = '/my-plant'}>
                                        <PlantIcon className="icon" />
                                        <span className={`icon-title ${isSideNav ? '' : 'display-none'}`}>My Plant</span>
                                    </div>
                                    <div className={`icon-container ${isMyGoals ? 'nav-selected' : ''}`} onClick={() => window.location.href = '/my-goals'}>
                                        <GoalsIcon className="icon" />
                                        <span className={`icon-title ${isSideNav ? '' : 'display-none'}`}>My Goals</span>
                                    </div>
                                </div>
                                <div>
                                    {isAuthenticated ? (
                                        <div className='icon-container' onClick={handleLogout}>
                                            <UserIcon className="icon" />
                                            <span className={`icon-title ${isSideNav ? '' : 'display-none'}`}>Logout</span>
                                        </div>
                                    ) : (
                                        <div className='icon-container' onClick={() => window.location.href = '/login'}>
                                            <UserIcon className="icon" />
                                            <span className={`icon-title ${isSideNav ? '' : 'display-none'}`}>Login</span>
                                        </div>
                                    )}
                                    {/* <div className="give-container">
                                        <GiveIcon className='icon' />
                                        <span className={`icon-title ${isSideNav ? '' : 'display-none'}`}>Help CalmLeaf</span>
                                    </div> */}
                                </div>
                            </div>
                        </nav>
                    </header>
                </>
            )}
        </>
    );
}

